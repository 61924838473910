import { Chart, Tooltip, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { loadFonts } from './plugins/webfontloader'
import vuetify from './plugins/vuetify'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import '@vuepic/vue-datepicker/dist/main.css'
import Datepicker from '@vuepic/vue-datepicker';
// import QrcodeReaderVue3 from "qrcode-reader-vue3";
import VueExcelEditor from 'vue3-excel-editor'
import VueRouter from 'vue-router'

import { createApp } from 'vue'
import router from './router'
import store from './store'
// import { createI18n } from 'vue-i18n'
// import fr from './locale/fr.json'
// import en from './locale/en.json'
import App from './App.vue'

Chart.register(Tooltip, ChartDataLabels, ...registerables);

loadFonts()

// const messages = {
//     en: en,
//     fr: fr
// }

// const i18n = createI18n({
//     locale: 'fr',
//     messages,
// })


const app = createApp(App)

app.config.errorHandler = () => null;
app.config.warnHandler = () => null;

app.component('EasyDataTable', Vue3EasyDataTable);
app.component('DatePicker', Datepicker);
// app.use(i18n)
app.use(router)
app.use(store)
app.use(vuetify)
app.use(VueRouter)
app.use(VueExcelEditor)

// app.use(QrcodeReaderVue3);

app.mount('#app')