import axios from "axios";
var CryptoJS = require("crypto-js");

class DataApi {
    constructor() {
        this.URL = "https://" + process.env.VUE_APP_MAIN_URL + "/api/sav-gestion";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    get_clients(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_clients', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_parc(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_parc', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_stock(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_stock', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_or_liste_sav2(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_or_liste_sav2', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_or_liste_sav(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_or_liste_sav', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_techniciens(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_techniciens', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agences(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_agences', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_new_or_2(oRdata, callback) {
        let datas = { oRdata }
        axios.post(this.URL + '/v1/creat_new_or_2', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_new_or_1(oRdata, callback) {
        let datas = { oRdata }
        axios.post(this.URL + '/v1/creat_new_or_1', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    edit_or(oRdata, callback) {
        let datas = { oRdata }
        axios.post(this.URL + '/v1/edit_or', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    edit_or_2(oRdata, callback) {
        let datas = { oRdata }
        axios.post(this.URL + '/v1/edit_or_2', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    show_etadl(clnum, equipement, matricule, CARA_MATERIEL, callback) {
        console.log("show_etadl");
        let datas = { clnum, equipement, matricule, CARA_MATERIEL }
        axios.post(this.URL + '/v1/show_etadl', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    delete_old_moteur_by_link(link, callback) {
        let datas = { link }
        axios.post(this.URL + '/v1/delete_old_moteur_by_link', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_new_moteur_2_line(motorData, callback) {
        let datas = { motorData }
        axios.post(this.URL + '/v1/creat_new_moteur_2_line', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_new_moteur_1_line(motorData, callback) {
        let datas = { motorData }
        axios.post(this.URL + '/v1/creat_new_moteur_1_line', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_or_1_Liste_byLink(link, callback) {
        let datas = { link }
        axios.post(this.URL + '/v1/get_or_1_Liste_byLink', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_header(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_header', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_or_2_Liste_byLink(link, callback) {
        let datas = { link }
        axios.post(this.URL + '/v1/get_or_2_Liste_byLink', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    delete_or_by_id(id, callback) {
        let datas = { id }
        axios.post(this.URL + '/v1/delete_or_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    delete_or2_by_id(id, callback) {
        let datas = { id }
        axios.post(this.URL + '/v1/delete_or2_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    creat_tmp_pdf(id, file, callback) {
        let datas = { id, file }
        axios.post(this.URL + '/v1/creat_tmp_pdf', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    openPdf(id, callback) {
        let pdfUrl = this.URL + '/v1/SAV_pdf/' + id
        callback(pdfUrl)
    }

    send_pdf_by_email(emails, pdf, orNum, callback) {
        let datas = { emails, pdf, orNum }
        axios.post(this.URL + '/v1/send_pdf_by_email', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_client_by_id(id, callback) {
        let datas = { id }
        axios.post(this.URL + '/v1/get_client_by_id', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_matricul_by_name(mat, callback) {
        let datas = { mat }
        axios.post(this.URL + '/v1/get_matricul_by_name', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_client_liste_by_name(name, callback) {
        let datas = { name }
        axios.post(this.URL + '/v1/get_client_liste_by_name', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_matricule_liste_by_name(name, callback) {
        let datas = { name }
        axios.post(this.URL + '/v1/get_matricule_liste_by_name', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_article_liste_by_name(name, callback) {
        let datas = { name }
        axios.post(this.URL + '/v1/get_article_liste_by_name', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }


    get_or_options(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_or_options', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_sav_check_liste(callback) {
        let datas = {}
        axios.post(this.URL + '/v1/get_sav_check_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    file_upload(file, orNum, fileName, callback) {
        let datas = { file, orNum, fileName }

        axios.post(this.URL + '/v1/file_upload', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    photo_folder_liste(orNum, callback) {
        let datas = { orNum }

        axios.post(this.URL + '/v1/photo_folder_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    photo_delete(orNum, fileName, callback) {
        let datas = { orNum, fileName }

        axios.post(this.URL + '/v1/photo_delete', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    photo_download(orNum, fileName, callback) {
        let datas = { orNum, fileName }

        axios.post(this.URL + '/v1/photo_download', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }
}

export default new DataApi()