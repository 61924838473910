import { createStore } from 'vuex'

const store = createStore({
    state() {
        return {
            user: {},
            webApplicationToShow: []
        }
    },

})

export default store