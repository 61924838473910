<template>
  <v-dialog v-model="isDeleteDispoOpen" width="auto">
    <v-card align="center" class="pa-2">
      <h3 class="pa-2">Valider la suppression de la disponibilité
      </h3>
      <v-col>
        <v-row justify="center" align="center">
          <v-btn class="ma-1" color="#EF5350" style="color:white" @click="deleteEvent()">OUI</v-btn>
          <v-btn class="ma-1" color="#7986CB" style="" variant="outlined" @click="isDeleteDispoOpen = false">NON</v-btn>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
  <v-dialog v-model="isDeleteOpen" width="auto">
    <v-card align="center" class="pa-2">
      <h3 class="pa-2">Valider la libération de la disponibilité
      </h3>
      <v-col>
        <v-row justify="center" align="center">
          <v-btn class="ma-1" color="#EF5350" style="color:white" @click="freeEvent()">OUI</v-btn>
          <v-btn class="ma-1" color="#7986CB" style="" variant="outlined" @click="isDeleteOpen = false">NON</v-btn>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
  <v-dialog v-model="isEditionOpen" width="99%" max-width="800px">
    <v-card variant="flat" style="border: solid 1px lightgrey">
      <!-- <v-card-title>Modifications</v-card-title> -->
      <div class="pa-2 mb-2" style="
          width: 100%;
          font-size: 18px;
          color: white;
          background-color: #7986cb;
          font-weight: bold;
        " align="center">
        MODIFICATIONS
      </div>
      <v-col>
        <v-row>
          <v-col v-if="isAdmin" cols="12" align="center" class="pa-1">
            <v-card class="pa-2" variant="flat" style="border: solid 1px lightgrey">
              Choisir une date
              <DatePicker v-model="newDate" :timePicker="false" :format="'dd/MM/yyyy'" :modelType="'timestamp'"
                locale="fr" :enableTimePicker="false" autoApply></DatePicker>
            </v-card>
          </v-col>
          <v-col v-if="isAdmin" align="center" class="pa-1" cols="6">
            <v-card class="pa-2" variant="flat" style="border: solid 1px lightgrey">
              Choisir une heure de début
              <DatePicker v-model="newTimeStart" :timePicker="true" :textInput="true" autoApply></DatePicker>
            </v-card>
          </v-col>
          <v-col v-if="isAdmin" align="center" class="pa-1" cols="6">
            <v-card class="pa-2" variant="flat" style="border: solid 1px lightgrey">
              Choisir une heure de fin
              <DatePicker v-model="newTimeEnd" :timePicker="true" :textInput="true" autoApply></DatePicker>
            </v-card>
          </v-col>
          <v-col v-if="isAdmin" align="center" class="pa-1" cols="12">
            <v-card class="pa-2" variant="flat" style="border: solid 1px lightgrey">
              Type de disponibilité
              <v-select :items="rdvTypeListe" item-title="label" item-value="KEY" variant="outlined" hide-details multiple
                density="compact" v-model="newTypeId" chips closable-chips>
              </v-select>
            </v-card>
            <v-card class="pa-2 mt-2" variant="flat" style="border: solid 1px lightgrey">
              Agences séléctionnée
              <!-- {{  newAgency }} -->
              <v-select :items="agencyList" item-title="WEB_LABEL" return-object variant="outlined" hide-details
                density="compact" v-model="newAgency">
              </v-select>
            </v-card>
          </v-col>
          <v-col align="center" class="pa-1" cols="12" v-if="this.LIBRE == 1">
            <v-card class="pa-2" variant="flat" style="border: solid 1px lightgrey">
              <div>
                STATUT
              </div>
              <v-divider></v-divider>
              <div>
                <v-btn-toggle v-model="newStatut" class="ma-2">
                  <v-btn style="font-size:20px;" variant="outlined" :value="1"
                    :style="newStatut == 1 ? 'background-color:#7986CB;' : ''">
                    ✅
                  </v-btn>
                  <v-btn style="font-size:23px;color:grey" variant="outlined" :value="2"
                    :style="newStatut == 2 ? 'background-color:#7986CB;' : ''">
                    ⚠️
                  </v-btn>
                  <v-btn style="font-size:20px;" variant="outlined" :value="3"
                    :style="newStatut == 3 ? 'background-color:#7986CB;' : ''">
                    ❌
                  </v-btn>
                </v-btn-toggle>
              </div>
            </v-card>
          </v-col>
          <v-col v-if="this.LIBRE == 1" align="center" class="pa-1" cols="12">
            <v-card class="pa-2" variant="flat" style="border: solid 1px lightgrey">
              Commentaire
              <v-textarea v-model="newComment" variant="outlined" rows="1"></v-textarea>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="this.LIBRE == 0" class="mb-2">
            <v-card variant="flat" class="pa-2" style="border: solid 1px lightgrey">
              <v-row justify="center">
                <v-col cols="" align="center">
                  <v-chip style="border:none; " :color="manualySetDispo ? 'red' : 'green'" class="py-5" align="center">
                    Occuper la disponibilité manuellement
                    <v-switch class="ml-3" v-model="manualySetDispo" hide-details inset align="center" density="compact"
                      :label="`${manualySetDispo == true ? 'Oui' : 'Non'}`">
                    </v-switch>
                  </v-chip>
                </v-col>
                <v-col cols="6" v-if="manualySetDispo && findedClient == undefined">
                  <v-text-field placeholder="Nom client ou Code client" class="" density="compact" hide-details
                    variant="outlined" v-model="searchedClient">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-btn v-if="findedClient == undefined && manualySetDispo" width="100%" class="mt-2" color="#5C6BC0"
                style="color:white" @click="searchClient()">Rechercher</v-btn>
              <div v-if="findedClient && manualySetDispo" class="pa-2 mt-2" variant="flat"
                style="border: solid 1px lightgrey" align="center">
                <div>
                  <v-autocomplete label="Client sélectionné" :items="clientListe" v-model="findedClient" return-object
                    item-title="SOCIETE"></v-autocomplete>
                  <span @click="findedClient = undefined" style="cursor:pointer"> <v-btn class="ml-5" color="#E57373"
                      style="color:white" density="compact">Annuler</v-btn>
                  </span>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="6" class="pa-1" v-if="manualySetDispo == false || findedClient != undefined">
            <v-btn :disabled="!saveBtnDisabled" style="width: 100%; color: white" color="#66BB6A" @click="saveEdit()">
              Enregistrer
            </v-btn>
          </v-col>
          <v-col cols="" class="pa-1">
            <v-btn style="width: 100%" color="#EF5350" variant="outlined" @click="isEditionOpen = false">
              Annuler
            </v-btn>
          </v-col>
          <v-col cols="12" class="pa-1">
            <v-btn color="#EF5350" style="width: 100%;color:white" @click="isDeleteDispoOpen = true">
              Supprimer la disponibilité
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
  <v-row justify="center" align="center" v-if="isEditionOpen == false && isDeleteOpen == false">
    <v-col>
      <v-card v-if="isLoading == false">
        <v-col class="pb-1" cols="12">
          <v-table density="compact">
            <tbody>
              <tr>
                <td>Client</td> 
                <td> {{ info['NOM'] }} {{ info['PRENOM'] }}  </td> 
              </tr>
              <tr v-for="item in keyToShow" :key="item.key">
                <td v-if="item.label">{{ item.label }}</td>
                <td>{{ info[item.key] }}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>
                  <span v-for="id in TYPE_ID" :key="id">
                    <span
                      v-if="rdvTypeListe.find((val) => val.KEY == id) && rdvTypeListe.find((val) => val.KEY == id).label">
                      {{ rdvTypeListe.find((val) => val.KEY == id).label }} /
                    </span>
                  </span>
                </td>
              </tr>
              <tr>
                <td>Début</td>
                <td>
                  {{ modalData.dates.start }}
                </td>
              </tr>
              <tr>
                <td>Fin</td>
                <td>
                  {{ modalData.dates.end }}
                </td>
              </tr>
              <tr v-if="dataMateriels && LIBRE == 1">
                <td>
                  Contenu
                </td>
                <td style="" class="pa-2">
                  <v-col v-for="elem, idx in dataMateriels" :key="idx" style="border: solid 1px lightgrey" class="">
                    <v-row class="">
                      <v-col cols="10" style="border-right:solid 1px lightgrey" class="">
                        {{ elem.label }}
                      </v-col>
                      <v-col cols="" align="center">
                        {{ elem.qte }}
                      </v-col>
                    </v-row>
                  </v-col>
                </td>
              </tr>
              <tr v-if="LIBRE == 1">
                <td>Commentaire</td>
                <td v-if="commentaire.length > 0" class="pa-2">
                  <v-card class="mt-3" variant="flat" style="border:solid 1px lightgrey">
                    <v-col style="max-height: 150px; height:auto;overflow:auto;" class="">
                      <div v-for="elem, idx  in commentaireReversed" :key="idx">
                        <div :align="getUser.user_name != elem.user ? 'right' : 'left'">
                          <v-chip :color="getUser.user_name == elem.user ? '#2E7D32' : '#283593'" class="my-1">
                            <div style="font-weight: bold;">
                              {{ elem.date }} -
                              {{ elem.user }} :
                            </div>
                            <div class="pl-2" style="color:black;">
                              {{ elem.msg }}
                            </div>
                          </v-chip>
                        </div>
                      </div>
                    </v-col>
                  </v-card>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
        <v-col class="px-5 py-4">
          <v-row>
            <v-col v-if="keyToShow.length > 0 || isAdmin" align="center" class="pa-1" cols="6">
              <v-btn variant="flat" color="#7986CB" style="width: 100%; color: white"
                @click="isEditionOpen = !isEditionOpen">
                Modifier
              </v-btn>
            </v-col>
            <v-col v-if="((keyToShow.length > 0 && isAdmin) || (LIBRE == 1 && isAdmin))" align="center" class="pa-1"
              cols="6">
              <v-btn variant="outlined" color="#EF5350" style="width: 100%;" @click="isDeleteOpen = true">
                Liberer la disponibilité
              </v-btn>
            </v-col>
            <v-col v-if="((keyToShow.length > 0 && isAdmin) || (LIBRE == 1 && isAdmin))" align="center" class="pa-1"
              cols="6">
              <v-btn color="#EF5350" style="width: 100%;color:white" @click="isDeleteDispoOpen = true">
                Supprimer la disponibilité
              </v-btn>
            </v-col>
            <v-col align="center" class="pa-1 pb-2" cols="">
              <v-btn variant="flat" style="width: 100%;color: white" color="#EF5350" @click="close()">
                Fermer
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
      <div v-else style="position:absolute;right:0px;left:0px;top:-10vh;" align="center">
        <v-progress-circular style="color: white" :size="150" :width="7" indeterminate>Chargement
        </v-progress-circular>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import engine from "../core/dataApi";
import moment from "moment";
// import momentTimezone from 'moment-timezone'

export default {
  data() {
    return {
      clientListe: [],
      saveBtnDisabled: true,
      findedClient: undefined,
      searchedClient: undefined,
      manualySetDispo: false,
      newDate: undefined,
      newTimeEnd: undefined,
      newTimeStart: undefined,
      newComment: undefined,
      newAgency: undefined,
      newStatut: 0,
      newTypeId: [],
      info: undefined,
      // data: undefined,
      dataMateriels: undefined,
      commentaire: undefined,
      RDV_VALIDE: undefined,
      LIBRE: undefined,
      TYPE_ID: undefined,
      isLoading: true,
      isDeleteOpen: false,
      isEditionOpen: false,
      isDeleteDispoOpen: false,
      keyToShow: [],
      timeStart: undefined,
      timeEnd: undefined,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.state.user.admin == 1 ? true : false
    }, commentaireReversed() {
      let newCom = [...this.commentaire];
      return newCom.reverse()
    },
    getUser() {
      return this.$store.state.user
    }
  },
  mounted() {
    let that = this;
    let num = this.modalData.info.extendedProps.data.RESERVATION_NUM;
    this.RDV_VALIDE = this.modalData.info.extendedProps.data.RDV_VALIDE
    this.LIBRE = this.modalData.info.extendedProps.data.LIBRE
    this.TYPE_ID = JSON.parse(JSON.stringify(this.modalData.info.extendedProps.data.TYPE_ID))
    this.typeListe = this.modalData.info.extendedProps.type
    this.info = this.modalData.info.extendedProps.data
    this.newAgency = this.agencyList.find((val) => val.PR_VILLE_AG == this.modalData.info.extendedProps.data.VILLE) //this.modalData.info.extendedProps.data.VILLE

    try {
      this.commentaire = JSON.parse(this.modalData.info.extendedProps.data.COMMENTAIRE);
    }
    catch {
      this.commentaire = []
    }
    engine.get_contract_by_num(num, function (data) {
      if (data.code == 0) {
        let cntData = data.data.data[0].fieldData;
        engine.get_contract_materiel_by_id(cntData.id, function (data2) {
          if (data2.code == 0) {
            that.dataMateriels = data2.data.data.map((val) => val.fieldData)
          }
        }
        )
      }
      if (that.LIBRE == 1)
        that.keyToShow = [
          // {
          //   key: "NOM",
          //   label: "Nom",
          // },
          // {
          //   key: "PRENOM",
          //   label: "Client",
          // },
          {
            key: "MATRICULE",
            label: "Matricule",
          },
          {
            key: "RESERVATION_NUM",
            label: "Numero de réservation",
          },
          {
            key: "CODE_CLIENT",
            label: "Code client",
          },
          {
            key: "MAIL",
            label: "Mail",
          },
          {
            key: "NUMERO",
            label: "Telephone",
          },
        ]

      that.isLoading = false;
    });
  },
  watch: {
    manualySetDispo(val) {
      if (val == false) {
        this.findedClient = undefined
        // this.searchedClient = undefined
      }
    },
    isEditionOpen(val) {
      let end = this.modalData.dates.end.split(":")
      let start = this.modalData.dates.start.split(":")
      this.timeStart = start
      this.timeEnd = end
      if (val == true) {
        this.newDate = moment(this.modalData.info.extendedProps.data.JOUR).format('MM/DD/YYYY')
        this.newComment = ''
        this.newTimeEnd = { "hours": end[0], "minutes": end[1], "seconds": 0 }
        this.newTimeStart = { "hours": start[0], "minutes": start[1], "seconds": 0 }
        this.newStatut = parseInt(this.RDV_VALIDE)
        this.newTypeId = JSON.parse(JSON.stringify(this.TYPE_ID))
        this.manualySetDispo = false
      }
      else {
        this.newDate = undefined
        this.newComment = undefined
        this.newTimeEnd = undefined
        this.newTimeStart = undefined
        this.newStatut = undefined
        this.newTypeId = JSON.parse(JSON.stringify(this.TYPE_ID))
        this.manualySetDispo = false
        this.findedClient = undefined
        this.searchedClient = undefined
        this.newAgency = undefined
      }
      this.newTypeId = this.newTypeId.filter((val) => val != 'undefined')
    }
  },
  props: {
    agencyList: {},
    modalData: {},
    resetFunction: {},
    rdvTypeListe: {},
  },
  methods: {
    searchClient() {
      let that = this
      engine.get_client_by_name_or_code_client(this.searchedClient, function (data) {
        if (data.code == 0) {
          that.clientListe = data.data.data.data.map((val) => val.fieldData)
          that.findedClient = data.data.data.data[0].fieldData
        }
      })
      // engine.get_client_by_code_client(this.searchedClient, function (data) {
      //   if (data.code == 0) {
      //     that.findedClient = data.data.data.data[0].fieldData
      //   }
      // })
    },
    saveEdit() {
      this.saveBtnDisabled = true
      this.newTimeStart.hours = String(this.newTimeStart.hours).length == 1 ? '0' + String(this.newTimeStart.hours) : this.newTimeStart.hours
      this.newTimeStart.minutes = String(this.newTimeStart.minutes).length == 1 ? '0' + String(this.newTimeStart.minutes) : this.newTimeStart.minutes
      this.newTimeEnd.hours = String(this.newTimeEnd.hours).length == 1 ? '0' + String(this.newTimeEnd.hours) : this.newTimeEnd.hours
      this.newTimeEnd.minutes = String(this.newTimeEnd.minutes).length == 1 ? '0' + String(this.newTimeEnd.minutes) : this.newTimeEnd.minutes
      let comment = this.commentaire
      if (this.newComment.length > 0)
        comment.push({ user: this.getUser.user_name, msg: this.newComment, date: moment().format('DD/MM/YYYY HH:mm') })
      comment = JSON.stringify(comment)
      if (this.newStatut == undefined || this.newStatut == null || isNaN(this.newStatut))
        this.newStatut = 0
      let sendMail = false
      let newData = {
        JOUR: parseInt(moment(this.newDate).set({ 'h': 12 }).format('x')),
        DEBUT: this.newTimeStart.hours + ':' + this.newTimeStart.minutes,
        FIN: this.newTimeEnd.hours + ':' + this.newTimeEnd.minutes,
        // TYPE_ID: this.newTypeId,
        COMMENTAIRE: comment,
        HEBDOMADAIRE: 0,
        RDV_VALIDE: this.newStatut,
        ID_HEBDOMADAIRE: "",
      }
      if (this.findedClient) {
        // newData['NOM'] = this.findedClient['nom']
        // newData['PRENOM'] = this.findedClient['prenom']
        newData['PRENOM'] = this.findedClient['SOCIETE']
        newData['MAIL'] = this.findedClient['CONTACT_MAIL(1)']
        newData['CODE_CLIENT'] = this.findedClient['CODE_CLIENT']
        newData['NUMERO'] = this.findedClient['TELEPHONE']
      }

      newData['VILLE'] = this.newAgency['PR_VILLE_AG']
      newData['AGENCE'] = this.newAgency['PR_NOM_AG']
      // newData['ADRESSE'] = this.newAgency['PR_ADRESSE_AG']
      newData['ADRESSE'] = this.newAgency['ADRESSE']
      newData['AGENCE_MAP_LINK'] = this.newAgency['GPS']
      newData['AGENCE_INDICATIONS'] = this.newAgency['INDICATIONS']

      for (let i = 0; i < this.rdvTypeListe.length; i++) {
        let key = 'TYPE_ID(' + (i + 1) + ')'
        newData[key] = ""
      }
      for (let i = 0; i < this.newTypeId.length; i++) {
        let key = 'TYPE_ID(' + (i + 1) + ')'
        newData[key] = this.newTypeId[i]
      }
      if (this.timeStart.join(':') != this.newTimeStart.hours + ':' + this.newTimeStart.minutes)
        sendMail = true
      if (this.timeEnd.join(':') != this.newTimeEnd.hours + ':' + this.newTimeEnd.minutes)
        sendMail = true
      if (moment(this.modalData.info.extendedProps.data.JOUR).format('MM/DD/YYYY') != moment(this.newDate).format('MM/DD/YYYY'))
        sendMail = true
      if (newData['VILLE'] != this.modalData.info.extendedProps.data.VILLE)
        sendMail = true

      if (this.manualySetDispo) {
        newData.LIBRE = 1
      }
      let that = this
      engine.edit_dispo(this.modalData.info.extendedProps.data['Clé primaire'], newData, sendMail, function (data) {
        if (data.code == 0) {
          // if (newData['CODE_CLIENT'] && newData['PRENOM'] && newData['NOM'] && newData['MAIL']) {
          if (newData['CODE_CLIENT'] && newData['PRENOM'] && newData['MAIL']) {
            newData['AGENCE'] = that.modalData.info.extendedProps.data['AGENCE']
            newData['ADRESSE'] = that.modalData.info.extendedProps.data['ADRESSE']
            newData['AGENCE_MAP_LINK'] = that.modalData.info.extendedProps.data['AGENCE_MAP_LINK']
            newData['AGENCE_INDICATIONS'] = that.modalData.info.extendedProps.data['AGENCE_INDICATIONS']
            engine.validation_dispo_mail(newData, function () {
            })
          }
        }
        that.resetFunction()
        that.saveBtnDisabled = false
      })
      that.close()
    },
    deleteEvent() {
      let that = this
      engine.delete_dispo(this.modalData.info.extendedProps.data['Clé primaire'], function () {
        that.close()
        that.resetFunction()
      })
    },
    freeEvent() {
      let that = this
      let newData = { LIBRE: 0, RESERVATION_NUM: '', COMMENTAIRE: '', NOM: '', PRENOM: '', MAIL: '', NUMERO: '', CODE_CLIENT: '', VELO_TYPE: '', RDV_VALIDE: '' }
      engine.edit_dispo(this.modalData.info.extendedProps.data['Clé primaire'], newData, true, function () {
        that.close()
        that.resetFunction()
      })
    },
    close() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style></style>