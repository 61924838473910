<template>
  <div class="email-visualisation">
    <v-dialog
      v-if="emailDetailData"
      v-model="isEmailDetailDataOpen"
      max-width="600px"
      min-width="90%"
    >
      <v-card>
        <v-col>
          <v-col style="font-weight: bold; font-size: 20px">
            Sujet : {{ emailDetailData.subject }}
          </v-col>
          <v-divider></v-divider>
          <v-col>
            <v-card
              class=""
              variant="flat"
              style="overflow-x: auto; overflow-y: auto; max-height: 600px"
            >
              <v-col>
                <div v-html="emailDetailData.body"></div>
              </v-col>
            </v-card>
          </v-col>
          <v-row class="mx-1">
            <v-col
              class="px-0"
              v-for="(value, key) in emailDetailData.events"
              :key="key"
              align="center"
            >
              <v-card
                class="ma-2"
                variant="plain"
                style="border: solid 1px lightgrey"
              >
                <v-col class="pa-1">
                  {{ stateTranslation[key] }}
                  <div v-for="elem in value" :key="elem">
                    {{ getFormatedDate(elem) }}
                    <!-- {{ moment(elem).format("DD/MM/YYYY HH:mm:ss") }} -->
                  </div>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
          <v-col align="center" class="pa-0">
            <v-btn
              class="mt-2"
              style="background-color: red; font-weight: bold"
              color="white"
              variant="outlined"
              @click="isEmailDetailDataOpen = false"
              >Fermer</v-btn
            >
          </v-col>
        </v-col>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col class="px-4 pb-0" align="center">
        <v-card class="mb-2 pa-2" outlined>
          <h1 class="mb-0">EMAIL HISTORIQUE</h1>
          <h5 class="ml-1">Séléctionner une adresse mail</h5>
          <v-autocomplete
            class="px-2"
            variant="outlined"
            dense
            v-model="selectedEmail"
            :items="authorizedEmailList"
            no-data-text="Aucune adresse mail disponible"
          ></v-autocomplete>
        </v-card>
        <div
          v-if="emailsListToShow && isLoading == false"
          style="background-color: white"
        >
          <v-text-field
            v-model="searchValue"
            label="Recherche"
            variant="outlined"
            class="pa-2 pt-3"
            density="compact"
            hide-details
            style="background-color: white"
          ></v-text-field>
          <EasyDataTable
            ref="dataTable"
            style="height: 60.5vh; overflow: auto"
            :empty-message="'Aucun mail'"
            :rows-per-page="nbrPagination"
            :headers="headers"
            :items="emailsListToShowFiltered[type]"
            @click-row="getEmailDetail"
            hide-footer
            :search-value="searchValue"
          />
          <v-pagination
            v-model="currentPaginationNumber"
            style="width: 80vw"
            class=""
            rounded="circle"
            :length="maxPaginationNumber"
          ></v-pagination>

        </div>
        <div
          v-else
          style="
        position: absolute;
        height:100%;
        width: 100%;
        top:0px;
        background-color:rgb(0,0,0,0.5)
        z-index: 3;
        padding-top:40vh;"
          align="center"
        >
          <v-progress-circular size="120" indeterminate color="white"
            >Chargement</v-progress-circular
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import engine from "../core";
import mainEngine from "@/core/index";
import axios from "axios";

export default {
  data() {
    return {
      searchValue: undefined,
      currentPaginationNumber: 1,
      nbrPagination: 40,
      type: "send",
      isLoading: true,
      stateTranslation: {
        sent: "Expédié",
        delivered: "Délivré",
        click: "Cliqué",
        opened: "Ouvert",
        open: "Ouvert",
        proxy_open: "Proxy ouvert",
        error: "Erreur",
        invalid_email: "Invalid email",
        blocked: "Bloqué",
      },
      headers: [
        { text: "Date", value: "date" },
        { text: "Expéditeur", value: "from" },
        { text: "Destinataire", value: "email" },
        { text: "Subject", value: "subject" },
        // { text: "Details", value: "details", fixed: true },
      ],
      isEmailDetailDataOpen: false,
      emailDetailData: undefined,
      selectedEmail: undefined,
      emailsListToShow: undefined,
      authorizedEmailList: undefined,
    };
  },
  mounted() {
    let that = this;
    let token = localStorage.getItem("userToken");
    mainEngine.get_user_by_token(token, function (data) {
      if (data.code == 0) {
        let user = data.data.data.data[0].fieldData;
        engine.sendinBlue_user_get_info(user.user_id, function callback(data) {
          if (data.code === 0) {
            let obj = data.data;
            that.authorizedEmailList = obj.MAILS.split("|");
            if (that.authorizedEmailList.length !== 0) {
              that.selectedEmail = that.authorizedEmailList[0];
            }
          } else {
            that.creatMsg = {
              value: "Identifiant ou mot de passe incorecte",
              color: that.errorColor,
            };
            setTimeout(function () {
              that.creatMsg = undefined;
            }, 5000);
          }
        });
      }
      that.isLoading = false;
    });
  },
  watch: {
    currentPaginationNumber(val) {
      this.$refs["dataTable"].updatePage(val);
    },
    selectedEmail(val) {
      let that = this;
      that.emailsListToShow = undefined;
      engine.sendinBlue_get_emails_list(val, function (data) {
        if (data.code == 0) {
          let mails = data.data.data.filter((val) =>
            moment(val.fieldData.date).isAfter(moment().subtract(30, "d"))
          );
          that.emailsListToShow = mails.map((val) => ({
            date: val.fieldData.date,
            email: val.fieldData.email,
            event: val.fieldData.event,
            from: val.fieldData.from,
            json: val.fieldData.json,
            subject: val.fieldData.subject,
            messageId: val.fieldData.messageId,
            api_key: val.fieldData.api_key,
          }));
        } else {
          that.emailsListToShow = [];
        }
      });
    },
  },
  methods: {
    getFormatedDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
    getEmailDetail(email) {
      let that = this;
      that.isLoading = true;
      let url =
        "https://api.sendinblue.com/v3/smtp/emails?messageId=%3C" +
        email.messageId.replaceAll("<", "").replaceAll(">", "") +
        "%3E&sort=desc&limit=500&offset=0";
      axios
        .get(url, {
          responseType: "json",
          credentials: false,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "api-key": email.api_key,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let data = res.data.transactionalEmails.find(
              (val) =>
                val.email == email.email &&
                val.from == email.from &&
                val.subject == email.subject
            );
            let url2 = "https://api.sendinblue.com/v3/smtp/emails/" + data.uuid;
            axios
              .get(url2, {
                responseType: "json",
                credentials: false,
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  "api-key": email.api_key,
                },
              })
              .then((res) => {
                if (res.status == 200) {
                  that.emailDetailData = res.data;
                  let objectEvent = {};
                  for (let i = 0; i < that.emailDetailData.events.length; i++) {
                    if (
                      objectEvent[that.emailDetailData.events[i].name] ==
                      undefined
                    ) {
                      objectEvent[that.emailDetailData.events[i].name] = [];
                    }
                    objectEvent[that.emailDetailData.events[i].name].push(
                      that.emailDetailData.events[i].time
                    );
                  }
                  that.emailDetailData.events = objectEvent;
                  that.isEmailDetailDataOpen = true;
                }
                that.isLoading = false;
              });
          }
        });
    },
  },
  computed: {
    maxPaginationNumber() {
      return Math.ceil(
        this.emailsListToShowFiltered[this.type].length / this.nbrPagination
      );
    },
    emailsListToShowFiltered() {
      if (!this.emailsListToShow) return [];
      let emails = {};
      emails["send"] = this.emailsListToShow
        .filter((val) => val.from === this.selectedEmail)
        .map((val) => ({
          date: moment(JSON.parse(val.json).date).format("DD/MM/YYYY HH:mm:ss"),
          email: val.email,
          from: val.from,
          subject: val.subject,
          messageId: val.messageId,
          api_key: val.api_key,
        }));
      emails["receive"] = this.emailsListToShow
        .filter((val) => val.email === this.selectedEmail)
        .map((val) => ({
          date: moment(JSON.parse(val.json).date).format("DD/MM/YYYY HH:mm:ss"),
          email: val.email,
          from: val.from,
          subject: val.subject,
          messageId: val.messageId,
          api_key: val.api_key,
        }));
      return emails;
    },
  },
};
</script>

<style>
/* .email-visualisation .v-pagination__list {
  display: none;
} */
</style>