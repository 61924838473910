<template>
  <v-card v-if="!isLoading" class="planing-livraison" color="#C8E6C9">
    <v-dialog v-model="isModalLivraisonOpen" width="90%" max-width="600">
      <v-card>
        <v-col>
          {{ modalData }}
        </v-col>
      </v-card>
      <!-- <modal-materiel-state
        :data="modalData"
        @close="modalData = {}"
        @closeStatesModal="isModalsOpen['eventModal'].value = false"
      ></modal-materiel-state> -->
    </v-dialog>
    <v-dialog v-model="isModalSettingsOpen" persistent>
      <modal-settings :eventColorByType="eventColorByType" :daysToHide="daysToHide"
        @closeSettingsModal="closeSettingsModal"></modal-settings>
    </v-dialog>
    <modal-livraison v-if="isModalLivraisonOpen && false" :data="modalData" @close="
      isModalLivraisonOpen = false;
    modalData = {};
    ">
    </modal-livraison>
    <!-- <modal-recuperation
      v-if="isModalRecuperationOpen"
      :data="modalData"
      @close="
        isModalRecuperationOpen = false;
        modalData = {};
      "
    >
    </modal-recuperation> -->
    <modal-chauffeur-settings v-if="isModalChauffeurSettingsOpen" :data="{ technicienList, technicienListToShow }"
      @close="isModalChauffeurSettingsOpen = false" @updatetechnicientsListToShow="updatetechnicientsListToShow($event)">
    </modal-chauffeur-settings>
    <full-calendar id="calendar" style="margin-top: 10px; margin-bottom: 10px" class="mx-2"
      :options="calendarOptions"></full-calendar>
    <div style="position: absolute; top: 84vh; right: 25px; z-index: 1;          background-color: #e0e0e0;
          border-style: none;
          padding: 5px;
          border-radius: 10px;
          box-shadow: 1px 1px 10px 2px grey;
">
      <button v-for="(value, key) in eventColorByType" :key="key" :style="{ 'background-color': value.color }" style="
          font-size: 15px;
          margin: 5px;
          border: solid 1px grey;
          padding: 10px;
          border-style: none;
          border-radius: 5px;
          color: black;
        ">
        <v-chip style="
            background-color: rgb(0, 0, 0, 0.5);
            color: white;
            cursor: pointer;
          ">
          {{ value.label }}
        </v-chip>
      </button>
      <!-- <div
        style="
          background-color: #eeeeee;
          border-style: none;
          padding: 5px;
          border-radius: 10px;
          box-shadow: 1px 1px 10px 2px grey;
        "
      >
        <button
          :style="{ 'background-color': eventColorByType['DEPART'] }"
          style="
            font-size: 15px;
            margin: 5px;
            border: solid 1px grey;
            padding: 10px;
            border-style: none;
            border-radius: 5px;
            color: white;
          "
        >
          Livraison
        </button>
        <button
          :style="{ 'background-color': eventColorByType['RETOUR'] }"
          style="
            font-size: 15px;
            margin: 5px;
            border: solid 1px grey;
            padding: 10px;
            border-style: none;
            border-radius: 5px;
            color: white;
          "
        >
          Récupération
        </button>
      </div> -->
    </div>

    <!-- <a href="fmp://demo-accen.dyndns.org/Contrat%20Loc.fmp12?script=affiche_contrat&param=CL270472">Open FM</a> -->
  </v-card>
  <v-col v-else align="center" style="margin-top: 40vh">
    <v-progress-circular size="120" indeterminate color="black">Chargement</v-progress-circular>
  </v-col>
</template>

<script>
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3";
import calendarData from "../hooks/index";
import ModalLivraison from "./modal-livraison.vue";
// import ModalMaterielState from "@/components/modal-materiel-state.vue";
import ModalChauffeurSettings from "./modal-settings.vue";
import { ref } from "@vue/reactivity";
import mainEngine from "@/core/index";
import engine from "../core/index";
import ModalSettings from "@/components/modal-planning-settings.vue";

export default {
  setup() {
    const isModalLivraisonOpen = ref(false);
    const isModalRecuperationOpen = ref(false);
    const modalData = ref({});

    const handleEventReceive = function () { };
    const handleEventDragTime = function () { };
    const handleEventClick = function (e) {
      let eventProps = e.event._def.extendedProps;
      isModalLivraisonOpen.value = true;
      modalData.value = e.event._def.extendedProps.data;
      modalData.value["type"] = eventProps.type;
    };
    const handleEventDrop = function () { };

    const {
      calendarOptions,
      isModalChauffeurSettingsOpen,
      isModalSettingsOpen,
      technicienList,
      eventColorByType,
      technicienListToShow,
      updateCalendarColors,
      updateCalendarDaysToShow,
      daysToHide,
    } = calendarData(
      handleEventClick,
      handleEventDrop,
      handleEventDragTime,
      handleEventReceive
    );
    return {
      calendarOptions,
      isModalSettingsOpen,
      isModalLivraisonOpen,
      isModalRecuperationOpen,
      isModalChauffeurSettingsOpen,
      modalData,
      technicienList,
      eventColorByType,
      technicienListToShow,
      updateCalendarColors,
      updateCalendarDaysToShow,
      daysToHide,
    };
  },
  watch: {
    isModalLivraisonOpen(val) {
      if (val == false) {
        this.modalData = {};
      }
    },
  },
  mounted() {
    this.isUserConnected();
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    closeSettingsModal(data) {
      let that = this;
      let days = data.daysListe.filter(
        (val) => !data.daysToShow.includes(val.idx)
      );
      if (days) {
        days = days.map((val) => val.idx);
        engine.save_day_to_hide(days.toString(), function () {
          that.updateCalendarDaysToShow();
        });
      }
      engine.save_calendar_colors(
        JSON.stringify(data.localColortab),
        function () {
          that.updateCalendarColors();
        }
      );
      this.isModalSettingsOpen = false;
    },
    isUserConnected() {
      let that = this;
      let token = localStorage.getItem("userToken");
      mainEngine.get_user_by_token(token, function (data) {
        if (data.code != 0) {
          localStorage.clear();
        }
        that.isLoading = false;
      });
    },
    updatetechnicientsListToShow(techList) {
      this.technicienListToShow = techList;
    },
  },
  components: {
    // ModalMaterielState,
    FullCalendar,
    ModalLivraison,
    // ModalRecuperation,
    ModalChauffeurSettings,
    ModalSettings,
  },
};
</script>

<style>
@import "../style.css";
</style>